import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { StaticImage } from "gatsby-plugin-image";
import Typography from "../components/Typography/Typography";
import MainLayout from "../components/mainlayout";
import { mobileViewBreakpoint } from "../components/helper";
import { Link } from "gatsby";

import { graphql } from "gatsby";

export const query = graphql`
  query blogListQuerySitemap($skip: Int, $limit: Int) {
    allStrapiArticle(
      filter: { status: { eq: "published" } }
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          strapiId
          slug
          title
          content
          publishedAt(formatString: "MMMM D, YYYY")
          description
          category {
            name
          }
          categories {
            name
            slug
          }
          image {
            childImageSharp {
              fluid(maxHeight: 444) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          author {
            name
            picture {
              childImageSharp {
                fixed(width: 30, height: 30) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  container: {
    boxSizing: "border-box",
    padding: "0px 32px",
    maxWidth: "1234px",
    margin: "80px auto 80px auto",
    "& p": {
      marginBottom: "20px"
    },
    "& > h2": {
      marginBottom: "28px",
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px",
        padding: "0px 16px"
      }
    },
    "& > h3": {
      color: "#122044",
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        padding: "0px 16px"
      }
    },
    "& > h5": {
      [theme.breakpoints.down(mobileViewBreakpoint)]: {
        marginBottom: "24px"
      }
    },

    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 0px 40px 0px",
      margin: "60px auto 0px auto"
    }
  },
  root: {
    margin: "0px -16px"
  }
}));

const Article = ({ data }) => {
  const classes = useStyles();

  var sitemapArray = [
    {
      name: "What we do",
      pages: [
        { name: "Transport & Logistics Services", href: "/services-overview/" },
        { name: "Event Transport", href: "/event-transportation-service/" },
        {
          name: "Transport Services",
          href: "/transportation-management-service/"
        },
        { name: "Logistics", href: "/event-logistics-service/" },
        { name: "Expo Transport", href: "/expo-transport-services/" }
      ]
    },
    {
      name: "About",
      pages: [
        { name: "About us", href: "/about-us/" },
        { name: "Case Studies", href: "/case-studies/" },
        {
          name: "Contact Us",
          href: "/contact-us/"
        }
      ]
    },
    {
      name: "Case Studies",
      pages: [
        {
          name: "Supplying and running technical buses for Dakar Rally",
          href: "/case-studies/dakar-rally/"
        },
        {
          name: "Ad-Diriyah Seasons, Riyadh",
          href: "/case-studies/ad-diriyah-seasons-riyadh/"
        },
        {
          name: "Abu Dhabi Formula One® Case Study",
          href: "/case-studies/abu-dhabi-formula-one/"
        },
        {
          name: "NBA India Games, 2019",
          href: "/case-studies/nba-india-games/"
        },
        {
          name: "Optimize your fleet transport",
          href: "/case-studies/strategic-fleet-optimization-utilization/"
        },
        {
          name: "Transportation Solutions – Rethought",
          href: "/case-studies/transportation-solutions-rethought/"
        }
      ]
    },
    {
      name: "Blog",
      href: "/blog/",
      pages: [
        { name: "Blog Page 1", href: "/blog/" },
        { name: "Blog Page 2", href: "/blog/page-2/" },
        { name: "Blog Page 3", href: "/blog/page-3/" },
        { name: "Blog Page 4", href: "/blog/page-4/" },
        { name: "Blog Page 5", href: "/blog/page-5/" }
      ]
    },
    {
      name: "Blog Posts",
      href: "/blog/",
      pages: data.allStrapiArticle.edges.map(({ node }) => {
        return { name: node.title, href: "/blog/" + node.slug + "/" };
      })
    }
  ];

  return (
    <MainLayout
      title={"MOTUS | ONE Sitemap"}
      h1
      banner={
        <StaticImage
          src="../assets/images/home/transport-logistics.jpg"
          draggable="false"
        />
      }
      breadcrumbs={[{ label: "Sitemap" }]}
      metaTitle={"Website Sitemap"}
      metaDescription={
        "Use the sitemap to find your way around motusone.com. Learn more about our transportation and logistics solutions and services."
      }
    >
      <div className={classes.root}>
        <div className={classes.container}>
          {sitemapArray.map(group => (
            <>
              <Typography component={"h2"} customVariant={"h2Bold"}>
                {group.name}
              </Typography>

              {group.pages.map(page => (
                <>
                  <a href={page.href}>
                    <Typography customVariant={"bodyLargeRegular"}>
                      {page.name}
                    </Typography>
                  </a>
                </>
              ))}

              <br />
              <br />
            </>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default Article;
